import n1 from "@assets/img/home/2022/n1.png";
import n1a from "@assets/img/home/2022/n1_a.png";
import n2 from "@assets/img/home/2022/n2.png";
import n2a from "@assets/img/home/2022/n2_a.png";
import n3 from "@assets/img/home/2022/n3.png";
import n3a from "@assets/img/home/2022/n3_a.png";
import n4 from "@assets/img/home/2022/n4.png";
import n4a from "@assets/img/home/2022/n4_a.png";
import n5 from "@assets/img/home/2022/n5.png";
import n5a from "@assets/img/home/2022/n5_a.png";
import n6 from "@assets/img/home/2022/n6.png";
import n6a from "@assets/img/home/2022/n6_a.png";
import n7 from "@assets/img/home/2022/n7.png";
import n7a from "@assets/img/home/2022/n7_a.png";

export default [
  {
    img: n1,
    hover: n1a,
    text: "动、静态壁纸",
    link: "/product?label=bz",
  },
  {
    img: n2,
    hover: n2a,
    text: "动态屏保",
    link: "/product?label=qnzj",
  },
  {
    img: n3,
    hover: n3a,
    text: "蜂巢收纳",
    link: "/product?label=qnzj",
  },
  {
    img: n4,
    hover: n4a,
    text: "电脑美化",
    link: "/product?label=dnmh",
  },
  {
    img: n5,
    hover: n5a,
    text: "任务栏优化",
    link: "/product?label=qnzj",
  },
  {
    img: n6,
    hover: n6a,
    text: "待办清单",
    link: "/product?label=qnzj",
  },
  {
    img: n7,
    hover: n7a,
    text: "更多",
    link: "/product",
  },
];
