<!--  -->
<template>
  <div class="indexpage">
    <div class="top_box" v-if="carousels.length > 0">
      <el-carousel
        :interval="5000"
        arrow="always"
        height="866"
        :class="[carousels.length == 1 ? '_disabled' : '']"
      >
        <el-carousel-item v-for="(item, key) in carousels" :key="key">
          <div class="upup_carousel_item">
            <img :src="item.cover_url" class="bg_img" />
            <div class="link_area">
              <DownloadBtn class="link1" v-if="item.type == 1"/>
              <a :href="item.ad_url" class="link2" target="_blank"></a>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="top_cards">
        <div class="top_cards_inner">
          <div class="_cards" v-for="(item, key) in cards" :key="key">
            <a :href="item.link" :title="item.text">
              <img :src="item.img" class="_hover" />
              <img :src="item.hover"/>
              {{ item.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="top_box" v-else>
      <img src="../../assets/img/recommends/loading.gif" class="loading dbpa middle" />
    </div>
    <div class="_blocks _blocks1">
      <div class="_blocks_inner">
        <div class="_left_block">
          <img src="../../assets/img/home/2022/b1-1.png" />
          <p>手机电脑账号通用，双端美化你的设备</p>
          <DownloadBtn />
        </div>
        <div :class="['_right_block', ani1 ? 'ani1' : '']" ref="ref1">
          <img src="../../assets/img/home/2022/b1-2.png" />
          <img
            src="../../assets/img/home/2022/b1-3.png"
            :class="['_float', ani1 ? 'ani2' : '']"
          />
        </div>
      </div>
    </div>
    <div class="_blocks _blocks2">
      <div class="_blocks_inner">
        <div :class="['_left_block', ani2 ? 'ani1' : '']" ref="ref2">
          <img src="../../assets/img/home/2022/b2-1.png" />
          <img
            src="../../assets/img/home/2022/b2-2.png"
            :class="['_float', ani2 ? 'ani2' : '']"
          />
          <img
            src="../../assets/img/home/2022/b2-3.png"
            :class="['_float2', ani2 ? 'ani2' : '']"
          />
        </div>
        <div class="_right_block">
          <img src="../../assets/img/home/2022/b2-4.png" />
          <p>美化电脑、整理桌面、管理时间三位一体</p>
          <DownloadBtn />
        </div>
      </div>
    </div>
    <div class="_blocks _blocks3">
      <div class="_blocks_inner">
        <div class="_left_block">
          <img
            src="../../assets/img/home/2022/b3-1.png"
            :class="[ani3 ? 'ani1' : '']"
            ref="ref3"
          />
          <button
            title="点击观看"
            @mouseenter="btn_in = true"
            @mouseleave="btn_in = false"
            :class="[ani3 ? 'ani2' : '']"
            @click="playVideo"
          >
            <img src="../../assets/img/home/2022/b3-3.png" v-show="!btn_in" />
            <img src="../../assets/img/home/2022/b3-2.png" v-show="btn_in" />
          </button>
        </div>
      </div>
      <DownloadBtn />
    </div>
    <Footer />
    <!-- <meta name="referrer" content="never" /> -->
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import DownloadBtn from "./downloadbtn";
import cards from "./cards";
import { getCarousels } from "../../api/wallpaper";
const isMobile = (() => {
    const UA = window.navigator.userAgent.toLowerCase();
    let ios = (UA.match(/iphone/i) == 'iphone' || UA.match(/ipad/i) == 'ipad') ? !0 : !1,
        adr = UA.match(/android/i) == 'android' ? !0 : !1;
    return ios || adr;
})()

export default {
  name: "home",
  components: {
    Footer,
    DownloadBtn,
  },
  data() {
    return {
      carousels: [],
      cards,
      btn_in: false,
      ani1: false,
      ani2: false,
      ani3: false,
    };
  },
  created() {
    //跳到手机版
    if(isMobile) this.$router.push('/mobile');
    else this.getData();
  },
  mounted() {
    let scrollbarEl = this.$scrollbar.wrap;
    scrollbarEl.addEventListener("scroll", () => {
      let dom1 = this.$refs.ref1;
      let dom2 = this.$refs.ref2;
      let dom3 = this.$refs.ref3;
      this.cantSee(dom1, (re) => {
        this.ani1 = !re;
      });
      this.cantSee(dom2, (re) => {
        this.ani2 = !re;
      });
      this.cantSee(dom3, (re) => {
        this.ani3 = !re;
      });
    });
  },
  methods: {
    cantSee: (el, fn) => {
      if (el) {
        let rect = el.getBoundingClientRect();
        fn(
          rect.top >=
            (window.innerHeight || document.documentElement.clientHeight)
        );
      }
    },
    playVideo() {
      let data = {
        // url: require('../../assets/video/mobileWallpaper.mp4'),
        url: "https://pcsource.upupoo.com/official_website/video/index_main.mp4",
        poster: "",
        typeVideo: 1,
      };
      this.$bus.$emit("showVideoModel", data);
    },
    async getData() {
      let re = await getCarousels();
      this.carousels = re.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.indexpage {
  min-width: 1300px;
  /deep/.el-carousel__container {
    height: 866px;
    .el-carousel__arrow {
      width: 58px;
      height: 58px;
      background-color: transparent;
      i {
        display: none;
      }
      &.el-carousel__arrow--left {
        left: 82px;
        background-image: url(../../assets/img/home/2022/arrow_left_a.png);
        &:hover {
          background-image: url(../../assets/img/home/2022/arrow_left.png);
        }
      }
      &.el-carousel__arrow--right {
        right: 82px;
        background-image: url(../../assets/img/home/2022/arrow_right_a.png);
        &:hover {
          background-image: url(../../assets/img/home/2022/arrow_right.png);
        }
      }
    }
  }
  /deep/.el-carousel__indicator {
    width: 28px;
    height: 10px;
    background: #000000;
    opacity: 0.2;
    border-radius: 5px;
    padding: 0;
    margin: 26px 6px;
    &.is-active {
      width: 52px;
      opacity: 0.4;
    }
    .el-carousel__button {
      background-color: transparent;
    }
  }
  ._disabled {
    /deep/.el-carousel__container {
      .el-carousel__arrow {
        cursor: no-drop;
        &.el-carousel__arrow--left {
          background-image: url(../../assets/img/home/2022/arrow_left_d.png);
          &:hover {
            background-image: url(../../assets/img/home/2022/arrow_left_d.png);
          }
        }
        &.el-carousel__arrow--right {
          background-image: url(../../assets/img/home/2022/arrow_right_d.png);
          &:hover {
            background-image: url(../../assets/img/home/2022/arrow_right_d.png);
          }
        }
      }
    }
    /deep/.el-carousel__indicator {
      visibility: hidden;
    }
  }
}
.top_box {
  position: relative;
  width: 100%;
  height: 866px;
  .loading {
    width: 400px;
  }
}
.dbpa {
  display: block;
  position: absolute;
}
.middle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.middle2 {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.middle_item {
  width: 1920px;
  height: 866px;
  @extend .middle;
}
.upup_carousel_item {
  position: relative;
  width: 100%;
  height: 866px;
  .bg_img {
    display: block;
    @extend .middle_item;
    z-index: 1;
  }
  .link_area {
    @extend .middle_item;
    z-index: 2;
    .link1 {
      @extend .dbpa;
      z-index: 2;
    }
    .link2 {
      width: 100%;
      height: 584px;
      @extend .dbpa;
      z-index: 1;
      top: 0;
      left: 0;
    }
  }
}
.top_cards {
  width: 100%;
  height: 185px;
  position: absolute;
  left: 0;
  bottom: 68px;
  z-index: 3;
  .top_cards_inner {
    @extend .middle;
    height: 185px;
    display: flex;
    justify-content: space-between;
    width: 1300px;
    ._cards {
      width: 160px;
      height: 184px;
      background: #FFFFFF;
      color: #161616;
      border-radius: 16px;
      text-align: center;
      transition: all 300ms linear;
      box-shadow: 0px 0px 16px 0px rgba(22,22,22,0.1);
      img {
        margin: 28px auto 32px;
        width: 80px;
        height: 80px;
        display: block;
        &._hover {
          display: none;
        }
      }
      &:hover {
        background: rgba(22, 22, 22, 0.5);
        color: #FFFFFF;
        img {
          display: none;
          &._hover {
            display: block;
          }
        }
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
._blocks {
  width: 100%;
  height: auto;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  ._blocks_inner {
    width: 1920px;
    min-height: 709px;
    @extend .middle2;
    ._left_block,
    ._right_block {
      position: absolute;
      ._float {
        display: block;
        position: absolute;
      }
    }
  }
  &._blocks1 {
    padding: 100px 0 153px;
    height: 709px;
    ._left_block {
      width: 408px;
      height: 276px;
      top: 147px;
      left: 310px;
      img {
        margin-bottom: 44px;
      }
      p {
        height: 25px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #161616;
        margin-bottom: 41px;
      }
    }
    ._right_block {
      width: 1120px;
      height: 709px;
      top: 0px;
      right: 130px;
      ._float {
        right: 99px;
        bottom: 93px;
      }
    }
  }
  &._blocks2 {
    padding: 0 0 143px;
    height: 709px;
    ._left_block {
      top: 0;
      left: 130px;
      width: 942px;
      height: 709px;
      ._float {
        top: 80px;
        left: 132px;
      }
      ._float2 {
        bottom: 65px;
        right: -29px;
        display: block;
        position: absolute;
      }
    }
    ._right_block {
      width: 432px;
      height: 274px;
      top: 135px;
      right: 310px;
      text-align: right;
      img {
        margin-bottom: 44px;
        display: inline;
      }
      p {
        height: 25px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #161616;
        margin-bottom: 41px;
      }
      a.download {
        float: right;
        text-align: left;
      }
    }
  }
  &._blocks3 {
    padding: 0 0 70px;
    height: 780px;
    ._left_block {
      width: 1340px;
      height: 642px;
      @extend .middle2;
      img {
        @extend .dbpa;
      }
      button {
        width: 154px;
        height: 154px;
        right: 177px;
        bottom: 93px;
        cursor: pointer;
        @extend .dbpa;
        border: none;
        background: none;
        img {
          position: relative;
        }
      }
    }
    .download {
      top: 714px;
      @extend .middle2;
    }
  }
  .ani1 {
    -webkit-animation: move-up 1.5s;
    -moz-animation: move-up 1.5s;
    animation: move-up 1.5s;
  }
  .ani2 {
    -webkit-animation: rect2 1.2s 0.3s both alternate infinite linear;
    -moz-animation: rect2 1.2s 0.3s both alternate infinite linear;
    animation: rect2 1.2s 0.3s both alternate infinite linear;
  }

  @-webkit-keyframes move-up {
    from {
      top: 30px;
      visibility: hidden;
      opacity: 0;
    }
    to {
      top: 0;
      visibility: visible;
      opacity: 1;
    }
  }
  @-moz-keyframes move-up {
    from {
      top: 30px;
      visibility: hidden;
      opacity: 0;
    }
    to {
      top: 0;
      visibility: visible;
      opacity: 1;
    }
  }
  @keyframes move-up {
    from {
      top: 30px;
      visibility: hidden;
      opacity: 0;
    }
    to {
      top: 0;
      visibility: visible;
      opacity: 1;
    }
  }

  @-webkit-keyframes rect2 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, 10px, 0);
    }
  }
  @-moz-keyframes rect2 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, 10px, 0);
    }
  }

  @keyframes rect2 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, 10px, 0);
    }
  }
}
/deep/ .el-carousel__container {
  max-width: 1920px;
  margin: 0 auto;
}
</style>
